import { Injectable } from '@angular/core';
import { AxiosInstance } from 'axios';
import { HttpClient } from '@angular/common/http';
import { RestClientService } from './rest-client.service';
import { ISitePage } from '../models/pages';
import { IHeaderRow } from '../models/header';

export interface ILoginRequest { username: string, password: string }

export interface ILoginResponse {
  access_token: string;
  refresh_token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private restClient?: AxiosInstance;

  get isRestClientReady(): boolean {
    return this.restClient != undefined;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly restClientService: RestClientService,
  ) {
    this.init();
  }

  public async init() {
    await this.restClientService.defaultInit();
    this.restClient = this.restClientService.restClient;
    // if (this.isRestClientReady) console.log("Rest client initialized for API service");
  }

  public header = {
    get: () => this.restClient?.get<IHeaderRow[]>('/menu') as unknown as Promise<IHeaderRow[]>,
  }

  public pages = {
    get: (body: {root: string, slug: string}) => this.restClient?.get<ISitePage>(`/v2/page/${body.root}/${body.slug}`) as any,
  }
}
