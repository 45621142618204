import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IExpertisePage } from '../../models/expertise';
import { PromCardsListComponent } from "../../components/prom-cards-list/prom-cards-list.component";
import { MediaSectionComponent } from "../../components/media-section/media-section.component";
import { CommonModule } from '@angular/common';
import { RoutesToPagesComponent } from '../../components/routes-to-pages/routes-to-pages.component';
import { SectionContactUsComponent } from "../../components/section-contact-us/section-contact-us.component";
import { SectionNewsHeadlinesComponent } from "../../components/section-news-headlines/section-news-headlines.component";
import { ApiService } from '../../services/api.service';
import { ISitePageCard } from '../../models/common';
import { LocaleEnum } from '../../models/common';
import { getYoutubeID, scrollToTop } from '../../services/utilities.service';
import { LongArrowDownComponent } from "../../components/long-arrow-down/long-arrow-down.component";
import { Subscription } from 'rxjs';
import { SectionReportsListComponent } from "../../components/section-reports-list/section-reports-list.component";
import { PageRoots } from '../../models/pages';

const ROOT_SLUG: PageRoots = 'expertise';
const VIDEO_SRC = '/assets/videos/EXP_cover.mp4';

@Component({
  selector: 'app-expertise-page',
  templateUrl: './expertise-page.component.html',
  styleUrl: './expertise-page.component.scss',
  standalone: true,
  imports: [CommonModule, PromCardsListComponent, MediaSectionComponent, RoutesToPagesComponent, SectionContactUsComponent, SectionNewsHeadlinesComponent, LongArrowDownComponent, SectionReportsListComponent],
})
export class ExpertisePageComponent implements OnInit, OnDestroy {

  readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SRC;

  get slug() {
    return this.activatedRoute.snapshot.paramMap.get('slug');
  }

  activatedRouteParamMapSubscription?: Subscription;

  loading: boolean = false;
  // pageData?: IExpertisePage;
  pageData?: Partial<IExpertisePage> = {
    locale: LocaleEnum.IT,
    category: 'Risk, Planning & Control',
    title: `
                  Credit Intelligence,
                  <br>
                  Optimization & Strategy
              `,
    subtitle: `
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  <br>
                  Nulla sed quam in orci scelerisque dignissim.
              `,
    body: `<p>We offer a business service that assists clients in crafting both
          <br>
          strategic and operational credit models, as well as optimizing
          <br>
          their life cycle stages (origination, management, and monitoring). 
          <br>
          This is achieved by leveraging our company's profound expertise
          <br>
          in modeling, research, and data science.</p>`,
    cards: {
      items: [
        {
          title: 'Credito Problematico',
          body: 'Strategie, modelli, soluzioni organizzative e<br>tecnologiche per una gestione tempestiva e<br>l\'ottimizzazione del credito problematico.',
          download:  {
            label: "play",
            url: "/cdn2/upload/2024/9/4d4bdf37-22cf-4756-8765-27b961280fc0.png"
          }
        },
        {
          title: 'Credit Advisory',
          body: 'Consulenza manageriale e soluzioni metodologiche e operative per raggiungere gli obiettivi aziendali, ottimizzare la gestione e garantire la conformità normativa.',
        },
        {
          title: 'Credito Problematico',
          body: 'Strategie, modelli, soluzioni organizzative e<br>tecnologiche per una gestione tempestiva e<br>l\'ottimizzazione del credito problematico.',
        },
        {
          title: 'Credit Advisory',
          body: 'Consulenza manageriale e soluzioni metodologiche e operative per raggiungere gli obiettivi aziendali, ottimizzare la gestione e garantire la conformità normativa.',
        }
      ] as ISitePageCard[],
    },
    mediaSection: {
      items: [
        {
          type: 'text',
          size: 'half',
          title: 'Want to know more? Scarica la brochure',
          download: {
            label: 'Download',
            url: 'https://prometeia.com/cdn/FILES/country-pages/cairo/Prometeia-deck.pdf'
          }
        },
        {
          type: 'video',
          size: 'half',
          title: '',
          url: 'https://www.youtube-nocookie.com/embed/8ZLmSNI9G5M?si=3bLtTwqtE5HzKTCT',
          cover: '/assets/images/tmp_EXP_Video_screenshot.png'
        }
      ]
    },
    // reports: {
    //   items: [
    //     {
    //       title: "Quarterly Economic Outlook",
    //       body: `Since 1974, the Quarterly Economic
    //             Outlook has contained analyses and
    //             forecasts of the global and Italian
    //             macroeconomic context and the
    //             evolution of the markets of reference
    //             for industrial and service companies,
    //             public utilities and public entities,
    //             banks and financial players. It
    //             represents the common reference
    //             framework for all the analyses and
    //             activities carried out by Prometeia.`,
    //       img: '/assets/images/report-bg-dark.png',
    //       textColor: 'white',
    //       route: '/reports/quarterly-economic-outlook',
    //     },
    //     {
    //       title: "Analisi dei Bilanci Bancari",
    //       body: `Since 1974, the Quarterly Economic
    //             Outlook has contained analyses and
    //             forecasts of the global and Italian
    //             macroeconomic context and the
    //             evolution of the markets of reference
    //             for industrial and service companies,
    //             public utilities and public entities,
    //             banks and financial players. It
    //             represents the common reference
    //             framework for all the analyses and
    //             activities carried out by Prometeia.`,
    //       img: '/assets/images/report-bg-light.png',
    //       route: '/reports/quarterly-economic-outlook',
    //     },
    //     {
    //       title: "Previsione dei Bilanci Bancari",
    //       body: `Since 1974, the Quarterly Economic
    //             Outlook has contained analyses and
    //             forecasts of the global and Italian
    //             macroeconomic context and the
    //             evolution of the markets of reference
    //             for industrial and service companies,
    //             public utilities and public entities,
    //             banks and financial players. It
    //             represents the common reference
    //             framework for all the analyses and
    //             activities carried out by Prometeia.`,
    //       img: '/assets/images/prom-gradient.png',
    //       textColor: 'white',
    //       route: '/reports/quarterly-economic-outlook',
    //     },
    //     {
    //       title: "Rapporto di Previsione",
    //       body: `Since 1974, the Quarterly Economic
    //             Outlook has contained analyses and
    //             forecasts of the global and Italian
    //             macroeconomic context and the
    //             evolution of the markets of reference
    //             for industrial and service companies,
    //             public utilities and public entities,
    //             banks and financial players. It
    //             represents the common reference
    //             framework for all the analyses and
    //             activities carried out by Prometeia.`,
    //       img: '/assets/images/prom-gradient.png',
    //       route: '/reports/quarterly-economic-outlook',
    //     },
    //     {
    //       title: "Report Personalizzato",
    //       body: `Since 1974, the Quarterly Economic
    //             Outlook has contained analyses and
    //             forecasts of the global and Italian
    //             macroeconomic context and the
    //             evolution of the markets of reference
    //             for industrial and service companies,
    //             public utilities and public entities,
    //             banks and financial players. It
    //             represents the common reference
    //             framework for all the analyses and
    //             activities carried out by Prometeia.`,
    //       img: '/assets/images/prom-gradient.png',
    //       route: '/reports/quarterly-economic-outlook',
    //     }
    //   ]
    // },
    solutions: {
      items: [
        {
          title: 'Loan Origination & Credit Decision',
          slug: 'sol_1'
        },
        {
          title: 'Asset Management',
          slug: 'sol_2'
        },
        {
          title: 'Another Solution',
          slug: 'sol_3'
        }
      ]
    },
    news: {
      items: [
        { category: 'case studies', title: 'Pillar 3 disclosure and climate transition risk: where do we stand?', slug: 'ins_1', img: '/assets/images/prom-gradient.png', types: ['report'] },
        { category: 'awards', title: 'Prometeia strengthens its positioning in the 2023 RiskTech100', slug: 'ins_2' },
        { category: 'news', title: 'Prometeia sponsor of RiskMinds International 2024', slug: 'ins_3' },
        { category: 'ideas', title: 'Prometeia Brief - Italy in the Global economy July 2024', slug: 'ins_4', img: '/assets/images/news-ideas-bg.png' },
      ]
    }
  };

  get isAlternateExpertisePage(): boolean {
    // return this.pageData?.category?.toLowerCase() === "Research".toLowerCase();
    return true;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly api: ApiService,
  ) { }

  ngOnInit(): void {
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('slug')) {
        // this.loadPage();
        scrollToTop();
      }
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamMapSubscription?.unsubscribe();
  }

  async loadPage() {
    // console.log(ROOT_SLUG, "->", this.activatedRoute.snapshot.paramMap.get('slug'));

    try {
      this.pageData = await this.api.pages.get({
        root: ROOT_SLUG,
        slug: this.slug || ''
      });

      this.editYoutubeVideoLinks();
    } catch (ex) {
      console.error(ex);
    }
  }

  editYoutubeVideoLinks() {
    this.pageData?.mediaSection?.items.forEach((item) => {
      if (item.type === 'video') {
        // console.log("YT ID:", getYoutubeID(item.url));
        item.url = `https://www.youtube-nocookie.com/embed/${getYoutubeID(item.url)}`;
      }
    });
  }

}
