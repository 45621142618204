<div [ngClass]="{'header': true, 'small': !isTop}" (mouseleave)="onMouseExitFirstLevel()">
    <div class="nav-bgc default-shadow"></div>

    <div class="header-content">
        <div class="logo-wrap">
            <img class="logo" src="assets/images/logo_chiaro.png" [routerLink]="['']" (click)="onMouseExitFirstLevel()"/>
        </div>
    
        @if (headerData) {
            <div class="navigation {{activeFirstLevel}}-is-active">
                <nav class="navigation--container first-level">
                    <ul class="navigation--list">
                        @for (fl of headerData; track fl.slug) {
                            <li [ngClass]="{'navigation--list--item first-level--list': true, 'btn': fl.slug === 'con', 'current': fl.slug === currentFirstLevel || fl.label.toLowerCase() === currentFirstLevel, 'active': fl.children?.length && fl.slug === activeFirstLevel, 'prev-active': fl.children?.length && fl.slug === previousActiveFirstLevel, 'moving-right': isActiveBarMovingRight, 'moving-left': isActiveBarMovingLeft}" (mouseenter)="onMouseOverFirstLevel(fl.slug)">
                                @if (fl.url) {
                                    <a class="first-level--label" href="{{fl.url}}">
                                        {{fl.label | uppercase}}
                                        <!-- 🔗 -->
                                        <!-- ↗ -->
                                        <img class="external-link default" src="/assets/icons/external_link__white.svg" alt="">
                                        <img class="external-link hovering" src="/assets/icons/external_link__green.svg" alt="">
                                    </a>
                                }
                                @else if (fl.slug && !fl.children?.length) {
                                    <a class="first-level--label" [routerLink]="[fl.slug]" (click)="activeFirstLevel = undefined;">
                                        {{fl.label | uppercase}}
                                    </a>
                                }
                                @else {
                                    <a class="first-level--label">
                                        {{fl.label | uppercase}}
                                    </a>
                                }
                            </li>
                        }
                    </ul>
                </nav>
        
                <div [ngClass]="{'navigation--container second-level': true, 'active': secondLevel.length !== 0}">
                    <div [ngClass]="{'second-level-content': true, 'centered': secondLevel.length === 1}">
                        @let lastExpertiseIndex = (secondLevel.length - 1);
                        @let lastSecondLevel = secondLevel[(secondLevel.length - 1)];
                        
                        @for (sl of secondLevel; track $index) {
                            @if (activeFirstLevel !== expertisePageRoot || $index < lastExpertiseIndex) {
                                <div class="second-level-column">
                                    @if (sl.label) {
                                        <p class="second-level--label">{{sl.label | uppercase}}</p>
                                    }
    
                                    <ul class="second-level-navigation-list">
                                        @for (element of sl.children; track element.slug) {
                                            @if (element.slug) {
                                                <li class="third-level-wrap" [routerLink]="[activeFirstLevel, element.slug]" (click)="clickOnThirdLevel()">
                                                    <a class="third-level--label">
                                                        {{element.label}}
                                                    </a>
                                                </li>
                                            }
                                            @else {
                                                <li class="third-level-wrap">
                                                    <a class="third-level--label">
                                                        {{element.label}}
                                                    </a>
                                                </li>
                                            }
                                        }
                                    </ul>

                                    <!-- if EXPERTISE is the active first level and it is the penultimate second level -->
                                    @if (activeFirstLevel === expertisePageRoot && $index == (lastExpertiseIndex - 1)) {
                                        @if (lastSecondLevel.label) {
                                            <p class="second-level--label">{{lastSecondLevel.label | uppercase}}</p>
                                        }
        
                                        <ul class="second-level-navigation-list">
                                            @for (element of lastSecondLevel.children; track element.slug) {
                                                @if (element.slug) {
                                                    <li class="third-level-wrap" [routerLink]="[activeFirstLevel, element.slug]" (click)="clickOnThirdLevel()">
                                                        <a class="third-level--label">
                                                            {{element.label}}
                                                        </a>
                                                    </li>
                                                }
                                                @else {
                                                    <li class="third-level-wrap">
                                                        <a class="third-level--label">
                                                            {{element.label}}
                                                        </a>
                                                    </li>
                                                }
                                            }
                                        </ul>
                                    }
                                </div>
                            }
                        }
                    </div>

                    <!-- <div class="second-and-third-level">
                        @for (sl of secondLevel; track sl.slug) {
                            @if (sl.label) {
                                <p class="second-level--label">{{sl.label | uppercase}}</p>
                            }

                            @for (element of sl.children; track element.slug) {
                                @if (element.slug) {
                                    <a class="third-level--label" [routerLink]="[activeFirstLevel, element.slug]" (click)="activeFirstLevel = undefined;">
                                        {{element.label}}
                                    </a>
                                }
                                @else {
                                    <a class="third-level--label">
                                        {{element.label}}
                                    </a>
                                }
                            }
                        }
                    </div> -->
                </div>
            </div>
        }

        <div class="quick-buttons">
            <div class="btn-container contact-us">
                <app-prom-animated-button [routerLink]="['contact-us', 'form']">
                    {{'GENERAL.CONTACT_US' | translate | uppercase}}
                </app-prom-animated-button>
            </div>

            <!-- <div class="btn-container reseved-area">
                @if(showLogout){
                    <button mat-icon-button (click)="logout()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="logout"></mat-icon></button>
                }
                @else if(!isLoggedAR && !showLogout){
                    <button mat-icon-button (click)="login()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock"></mat-icon></button>
                }
                @else{
                    <button mat-icon-button (click)="resevedArea()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock_open"></mat-icon></button>
                }
            </div> -->

            <!-- <div class="btn-container change-language">
                <button mat-button class="menu-button" [matMenuTriggerFor]="localizationMenu" aria-label="Menu button for localization" style="color: white">
                    {{'LOCALE' | translate | uppercase}}
                </button>
                <mat-menu #localizationMenu="matMenu">
                    <button mat-menu-item (click)="changeLanguage('it')">ITA</button>
                    <button mat-menu-item (click)="changeLanguage('en')">ENG</button>
                </mat-menu>
            </div> -->
        </div>
    </div>
</div>

<div class="mobile-header default-shadow">
    <div class="header-content">
        <div class="logo-wrap">
            <img class="logo" src="assets/images/logo_chiaro.png" [routerLink]="['']"/>
        </div>

        <div class="mobile-navigation" style="display: flex;">
            <div class="btn-container reseved-area" style="margin-top: -7px;">
                @if(showLogout){
                    <button mat-icon-button (click)="logout()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="logout"></mat-icon></button>
                }
                @else if(!isLoggedAR && !showLogout){
                    <button mat-icon-button (click)="login()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock"></mat-icon></button>
                }
                @else{
                    <button mat-icon-button (click)="resevedArea()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock_open"></mat-icon></button>
                }
            </div>

            <app-mobile-header-menu [data]="headerData"></app-mobile-header-menu>

            <div class="btn-container contact-us">
                <app-prom-animated-button [routerLink]="['contact-us', 'form']" size="small">
                    {{'GENERAL.CONTACT_US' | translate | uppercase}}
                </app-prom-animated-button>
            </div>
        </div>
    </div>
</div>
