import { Injectable } from '@angular/core';
import { HEADER_DATA__MOCK } from '../models/constants';
import { IHeaderRow } from '../models/header';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderDataService {

  private headerData?: IHeaderRow[];

  constructor(
    private readonly api: ApiService,
  ) { }

  async getHeaderData(options?: {ignoreCache?: boolean}): Promise<IHeaderRow[]> {
    if (!this.headerData || options?.ignoreCache) {
      // this.headerData = HEADER_DATA__MOCK;
      try {
        this.headerData = await this.api.header.get();
        if (this.headerData.find((x: any) => x.slug === 'sim') === undefined) {
          this.headerData.push(
            {slug: 'sim', label: 'ADVISOR SIM', url: 'https://www.prometeia.com/sim'},
          );
        }
        // console.log('Got header data', this.headerData);
      } catch (ex) {
        console.error('Error getting header data', ex);
      }
    } else {
      // console.log('Using cached header data', this.headerData);
    }

    return this.headerData as any;
  }

  clearHeaderData() {
    this.headerData = undefined;
  }
}
