import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISolutionsPage } from '../../models/solutions';
import { CommonModule } from '@angular/common';
import { MediaSectionComponent } from '../../components/media-section/media-section.component';
import { PromCardsListComponent } from '../../components/prom-cards-list/prom-cards-list.component';
import { RoutesToPagesComponent } from '../../components/routes-to-pages/routes-to-pages.component';
import { SectionContactUsComponent } from '../../components/section-contact-us/section-contact-us.component';
import { SectionNewsHeadlinesComponent } from '../../components/section-news-headlines/section-news-headlines.component';
import { ApiService } from '../../services/api.service';
import { ISitePageCard } from '../../models/common';
import { LocaleEnum } from '../../models/common';
import { getYoutubeID, scrollToTop } from '../../services/utilities.service';
import { LongArrowDownComponent } from "../../components/long-arrow-down/long-arrow-down.component";
import { Subscription } from 'rxjs';
import { PageRoots } from '../../models/pages';

const ROOT_SLUG: PageRoots = 'solutions';
const VIDEO_SRC = '/assets/videos/SOL_cover.mp4';

@Component({
  selector: 'app-solutions-page',
  templateUrl: './solutions-page.component.html',
  styleUrl: './solutions-page.component.scss',
  standalone: true,
  imports: [CommonModule, PromCardsListComponent, MediaSectionComponent, RoutesToPagesComponent, SectionContactUsComponent, SectionNewsHeadlinesComponent, LongArrowDownComponent],
})
export class SolutionsPageComponent implements OnInit, OnDestroy {

  readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SRC;

  get slug() {
    return this.activatedRoute.snapshot.paramMap.get('slug');
  }

  activatedRouteParamMapSubscription?: Subscription;

  loading: boolean = false;
  pageData?: ISolutionsPage;
  defaultPageData?: Partial<ISolutionsPage> = {
    locale: LocaleEnum.IT,
    category: 'Suites',
    title: `
                  Wealth
                  <br>
                  Management
              `,
    subtitle: `
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  <br>
                  Nulla sed quam in orci scelerisque dignissim.
              `,
    body: `<p>Le soluzioni Digital Wealth Management di Prometeia forniscono a
          banche, reti di consulenti e gestori gli strumenti indispensabili per una
          gestione integrata del patrimonio e degli investimenti, garantendo sempre
          la conformità alle normative più recenti.</p>`,
    cards: {
      items: [
        {
          title: 'Financial Advisory',
          body: `La piattaforma PFTPro® consente una consulenza finanziaria su più canali, che può essere
                configurata per adattarsi sia alle analisi complesse richieste dal segmento Private/HNWI,
                sia alle necessità di automazione e facilità d'uso del segmento retail/affluent. Può essere
                distribuita in vari modi, dal SaaS on cloud alle architetture tradizionali o ibride.`,
          download:  {
            label: "play",
            url: "/cdn2/upload/2024/9/4d4bdf37-22cf-4756-8765-27b961280fc0.png"
          }
        },
        {
          title: 'Systematic Advisory',
          body: `La piattaforma PFTPro® presenta numerosi moduli che si integrano con fonti di dati esterne
                (open e big data), permettendo di esaminare tutte le risorse del cliente, sia finanziarie, che
                immobiliari e aziendali. Questa piattaforma supporta la pianificazione per soddisfare
                svariate necessità familiari, come protezione, passaggio generazionale e previdenza.`,
        },
        {
          title: 'Robo-Advisory',
          body: `Aiuta i clienti a risparmiare in modo più intelligente, permettendo di impostare e monitorare
                online i loro portafogli. Prometeia permette di beneficiare di consigli di investimento
                sofisticati e personalizzati, basati sulla piena potenza degli algoritmi finanziari, di
                ottimizzazione e di intelligenza artificiale della piattaforma PFTPro®, forniti direttamente ai
                clienti, con il tuo brand, in un workflow facile da usare.`,
        },
        {
          title: 'Market Insights',
          body: `Prometeia comprende a fondo le complessità del mercato del Wealth & Asset Management
                e delle assicurazioni. Forte di tali competenze, assistiamo i clienti attraverso analisi di
                scenario e del contesto competitivo e regolamentare, a supporto delle scelte strategiche e
                di posizionamento commerciale.`,
        },
        {
          title: 'Reporting',
          body: `Prometeia comprende a fondo le complessità del mercato del Wealth & Asset Management
                e delle assicurazioni. Forte di tali competenze, assistiamo i clienti attraverso analisi di
                scenario e del contesto competitivo e regolamentare, a supporto delle scelte strategiche e
                di posizionamento commerciale.`,
        }
      ] as ISitePageCard[],
    },
    mediaSection: {
      items: [
        {
          type: 'image',
          size: 'full',
          title: '',
          url: '/assets/images/tmp_SOL_Dashboard_mockup.png'
        },
        {
          type: 'video',
          size: 'half',
          title: '',
          url: 'https://www.youtube-nocookie.com/embed/8ZLmSNI9G5M?si=3bLtTwqtE5HzKTCT',
          cover: '/assets/images/tmp_SOL_Video_screenshot.png'
        },
        {
          type: 'image',
          size: 'half',
          title: '',
          url: '/assets/images/tmp_SOL_Dashboard.png'
        },
        {
          type: 'text',
          size: 'full',
          title: 'Want to know more? Scarica la brochure',
          download: {
            label: 'Download',
            url: 'https://prometeia.com/cdn/FILES/country-pages/cairo/Prometeia-deck.pdf'
          }
        }
      ]
    },
    expertise: {
      items: [
        {
          title: 'Front Office Processes',
          slug: 'exp_1'
        },
        {
          title: 'Product Governance & Innovation',
          slug: 'exp_2'
        },
        {
          title: 'Service Models',
          slug: 'exp_3'
        },
        {
          title: 'Market Analysis',
          slug: 'exp_4'
        },
        {
          title: 'Regulation & Compliance',
          slug: 'exp_5'
        }
      ]
    },
    news: {
      items: [
        { category: 'events', title: 'Pillar 3 disclosure and climate transition risk: where do we stand?', slug: 'ins_1', date: new Date() },
        { category: 'awards', title: 'Prometeia strengthens its positioning in the 2023 RiskTech100', slug: 'ins_2' },
        { category: 'news', title: 'Prometeia sponsor of RiskMinds International 2024', slug: 'ins_3' },
        { category: 'ideas', title: 'Prometeia Brief - Italy in the Global economy July 2024', slug: 'ins_4', img: '/assets/images/news-ideas-bg.png' },
      ]
    }
  };
  saasCloudPageData?: Partial<ISolutionsPage> = {
    locale: LocaleEnum.IT,
    category: 'DELIVERY APPROACH',
    title: `
                  SaaS & Cloud
              `,
    subtitle: `Prometeia offers tech and regulatory solutions as a service,
              leveraging the expertise and the power of the Cloud
              provided by the most important market hyperscalers.`,
    intro: {
      title: 'What we do',
      body: `<p>The world is embracing the Cloud at an unprecedented pace,
            and the Software as a Service (SaaS) consumption model has
            quickly risen as the leading strategy, offering businesses a
            multitude of benefits, including enhanced adaptability,
            rebalancing from capital to operational expenditure, and
            unparalleled ease in innovation and scale.
            <br><br>
            Prometeia Cloud's SaaS offering stands at the vanguard of this
            revolution, enriching our core technology and regulatory
            solutions portfolio. Our clients gain access to a diverse array of
            cloud application services, meticulously crafted on top-tier
            hyperscale platforms and featuring uncompromising standards
            of security, resilience, privacy, and service excellence.</p>`
    },
    body: `<p>Le soluzioni Digital Wealth Management di Prometeia forniscono a
          banche, reti di consulenti e gestori gli strumenti indispensabili per una
          gestione integrata del patrimonio e degli investimenti, garantendo sempre
          la conformità alle normative più recenti.</p>`,
    cards: {
      items: [
        {
          title: 'Reliability',
          body: `Steady commitment to developing core internal
                expertise and skills to guarantee service levels and
                overall end-to-end accountability to our customers`,
          download:  {
            label: "play",
            url: "/cdn2/upload/2024/9/4d4bdf37-22cf-4756-8765-27b961280fc0.png"
          }
        },
        {
          title: 'Agility',
          body: `Best-of-breed technologies, strategic partnerships, and
                research to bring faster innovation and business agility into
                our solutions`,
        },
        {
          title: 'Trust',
          body: `Top security and compliance standards, both for
                ourselves and our customers, embedding them all along
                the entire solution and service life-cycles`,
        },
        {
          title: 'Best fit',
          body: `Open and composable approach to best integrate and fit
                within customers digital landscape at the best-balanced
                value-to-cost`,
        },
        {
          title: 'Modernization',
          body: `Application legacy debt reduction achievement
                throughout a replace strategy lowering modernization
                risks while achieving full benefits of cloud native stacks
                and technologies`,
        },
        {
          title: 'Business outcome',
          body: `Deployment of a SaaS portfolio to better align technology
                investments to direct business value and outcomes
                generated by its adoption over time`,
        }
      ] as ISitePageCard[],
    },
    mediaSection: {
        items: [
            {
                type: "text+image",
                size: "full",
                title: "Our Offerings",
                body: "Models managed by Prometeia can be integrated and mixed",
                url: "/cdn2/upload/2024/9/20f4d8f9-a245-4296-952d-3ec6801ed55e.png"
            },
            {
                type: "text",
                size: "half",
                link: {
                    label: "Discover More",
                    route: "/it/article/prometeia-aws"
                },
                title: "Prometeia & AWS"
            },
            {
                type: "text",
                size: "half",
                link: {
                    label: "Discover More",
                    route: "/it/article/prometeia-microsoft-azure"
                },
                title: "Prometeia & Microsoft Azure"
            },
            {
                type: "text+link",
                size: "full",
                link: {
                    label: "Discover More",
                    url: "http://www.google.it"
                },
                body: "At Prometeia, we prioritize security, resilience, and compliance in our technology solutions. Our structured governance and compliance program ensures clear certification processes, fostering trust and transparency to accelerate and simplify customers' management of complex multi-cloud and digital platform ecosystems while meeting regulatory requirements.",
                title: "Trust & Transparency"
            }
        ]
    },
    news: {
      items: [
        { category: 'events', title: 'Pillar 3 disclosure and climate transition risk: where do we stand?', slug: 'ins_1', date: new Date() },
        { category: 'awards', title: 'Prometeia strengthens its positioning in the 2023 RiskTech100', slug: 'ins_2' },
        { category: 'news', title: 'Prometeia sponsor of RiskMinds International 2024', slug: 'ins_3' },
        { category: 'ideas', title: 'Prometeia Brief - Italy in the Global economy July 2024', slug: 'ins_4', img: '/assets/images/news-ideas-bg.png' },
      ]
    }
  };

  get isAlternateSolutionsPage(): boolean {
    return (this.slug !== null && ["saas-cloud"].includes(this.slug)) ?? false;
    // return false;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly api: ApiService,
  ) { }

  ngOnInit(): void {
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('slug')) {
        if (this.isAlternateSolutionsPage && this.slug === 'saas-cloud') {  // TODO: remove this (it's a mockup)
          this.pageData = this.saasCloudPageData as ISolutionsPage;
          this.formatMediaTitles();
        } else {  // TODO: remove this (it's a mockup)
          this.pageData = this.defaultPageData as ISolutionsPage;
          this.formatMediaTitles();
        }
        // this.loadPage();
        scrollToTop();
      }
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamMapSubscription?.unsubscribe();
  }

  async loadPage() {
    // console.log(ROOT_SLUG, "->", this.activatedRoute.snapshot.paramMap.get('slug'));

    try {
      this.pageData = await this.api.pages.get({
        root: ROOT_SLUG,
        slug: this.slug || ''
      });

      this.editYoutubeVideoLinks();
      this.formatMediaTitles();
    } catch (ex) {
      console.error(ex);
    }
  }

  editYoutubeVideoLinks() {
    this.pageData?.mediaSection?.items.forEach((item) => {
      if (item.type === 'video') {
        // console.log("YT ID:", getYoutubeID(item.url));
        item.url = `https://www.youtube-nocookie.com/embed/${getYoutubeID(item.url)}`;
      }
    });
  }

  formatMediaTitles() {
    this.pageData?.mediaSection?.items.forEach((item) => {
      if (item.type === 'text' && item.size !== 'full' && item.title ) {
        item.title = item.title.replace(/ &/g, '<br>&');
      }
    });
  }
  
}
