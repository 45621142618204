import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ExpertisePageComponent } from './pages/expertise-page/expertise-page.component';
import { SolutionsPageComponent } from './pages/solutions-page/solutions-page.component';
import { ReservedAreaComponent } from './pages/reserved-area/reserved-area.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { CustomersPageComponent } from './pages/customers-page/customers-page.component';

export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'it' },
            {
                path: ':lang',
                component: HomePageComponent
            },

            { path: 'expertise/:slug', pathMatch: 'full', redirectTo: 'it/expertise/:slug' },
            {
                path: ':lang/expertise/:slug',
                component: ExpertisePageComponent
            },
            { path: 'solutions/:slug', pathMatch: 'full', redirectTo: 'it/solutions/:slug' },
            {
                path: ':lang/solutions/:slug',
                component: SolutionsPageComponent,
            },
            { path: 'customers/:slug', pathMatch: 'full', redirectTo: 'it/customers/:slug' },
            {
                path: ':lang/customers/:slug',
                component: CustomersPageComponent,
            },

            {
                path: 'reserved-area',
                component: ReservedAreaComponent
            }
        ]
    }
];
