import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PromCardsListComponent } from "../../components/prom-cards-list/prom-cards-list.component";
import { MediaSectionComponent } from "../../components/media-section/media-section.component";
import { CommonModule } from '@angular/common';
import { RoutesToPagesComponent } from '../../components/routes-to-pages/routes-to-pages.component';
import { SectionContactUsComponent } from "../../components/section-contact-us/section-contact-us.component";
import { SectionNewsHeadlinesComponent } from "../../components/section-news-headlines/section-news-headlines.component";
import { ApiService } from '../../services/api.service';
import { LocaleEnum } from '../../models/common';
import { getYoutubeID, scrollToTop } from '../../services/utilities.service';
import { LongArrowDownComponent } from "../../components/long-arrow-down/long-arrow-down.component";
import { Subscription } from 'rxjs';
import { SectionReportsListComponent } from "../../components/section-reports-list/section-reports-list.component";
import { ICustomersPage } from '../../models/customers';
import { PageRoots } from '../../models/pages';

const ROOT_SLUG: PageRoots = 'customers';

@Component({
  selector: 'app-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrl: './customers-page.component.scss',
  standalone: true,
  imports: [CommonModule, PromCardsListComponent, MediaSectionComponent, RoutesToPagesComponent, SectionContactUsComponent, SectionNewsHeadlinesComponent, LongArrowDownComponent, SectionReportsListComponent],
})
export class CustomersPageComponent implements OnInit, OnDestroy {

  readonly pageRoot: PageRoots = ROOT_SLUG;

  get slug() {
    return this.activatedRoute.snapshot.paramMap.get('slug');
  }

  activatedRouteParamMapSubscription?: Subscription;

  loading: boolean = false;
  // pageData?: ICustomersPage;
  pageData?: Partial<ICustomersPage> = {
    locale: LocaleEnum.IT,
    category: 'none',
    title: `Wealth Managers & Financial Advisors`,
    heroImage: '/assets/images/bg-customers.png',
    intro: {body: `<p>Our Prometeia Digital Wealth Management platform offering
            includes the SaaS Wealth Advisory Suite bringing new levels of
            performance, resilience and continuous innovation benefits to
            our customers.
            <br><br>
            Thanks to a constant and close collaboration with AWS,
            leveraging all partners programs and ISV and SaaS Factory
            team support, we have designed and deliver innovative SaaS
            cloud solutions, adopting container and serverless
            technologies as first choices, such as AWS Glue, Fargate,
            Lambda, Aurora, DynamoDB among others.</p>`},
    expertise: {
      items: [
        {
          title: 'Front Office Processes',
          slug: 'front-office-processes'
        },
        {
          title: 'Product Governance & Innovation',
          slug: 'product-governance-innovation'
        },
        {
          title: 'Service Models',
          slug: 'service-models'
        },
        {
          title: 'Market Analysis',
          slug: 'market-analysis'
        },
        {
          title: 'Regulation & Compliance',
          slug: 'regulation-compliance'
        }
      ]
    },
    expertiseResearch: {
      items: [
        {
          title: 'Front Office Processes',
          slug: 'front-office-processes'
        },
        {
          title: 'Product Governance & Innovation',
          slug: 'product-governance-innovation'
        },
        {
          title: 'Service Models',
          slug: 'service-models'
        }
      ]
    },
    mediaSection: {
      items: [
        {
          type: 'text+link',
          size: 'full',
          title: 'Training',
          body: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis ex
                ea commodo consequat. Duis autem vel eum iriure dolor in
                hendrerit in vulputate velit esse molestie consequat.
                Vel illum dolore eu feugiat nulla facilisis at vero eros et
                accumsan et iusto odio dignissim qui blandit praesent luptatum
                zzril delenit augue duis dolore te feugait nulla facilisi.`,
          link: {
            label: 'CONTACT US',
            route: '/contact-us'
          }
        }
      ]
    },
    solutions: {
      items: [
        {
          title: 'Loan Origination & Credit Decision',
          slug: 'sol_1'
        },
        {
          title: 'Asset Management',
          slug: 'sol_2'
        },
        {
          title: 'Another Solution',
          slug: 'sol_3'
        }
      ]
    },
    news: {
      items: [
        { category: 'ideas', title: 'Prometeia Brief - Italy in the Global economy July 2024', slug: 'ins_4', img: '/assets/images/news-ideas-bg.png', types: ['podcast'] },
        { category: 'events', title: 'Pillar 3 disclosure and climate transition risk: where do we stand?', slug: 'ins_1', date: new Date() },
        { category: 'awards', title: 'Prometeia strengthens its positioning in the 2023 RiskTech100', slug: 'ins_2' },
        { category: 'news', title: 'Prometeia sponsor of RiskMinds International 2024', slug: 'ins_3', types: ['video', 'dataviz', 'report'] },
      ]
    }
  };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly api: ApiService,
  ) { }

  ngOnInit(): void {
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('slug')) {
        // this.loadPage();
        scrollToTop();
      }
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamMapSubscription?.unsubscribe();
  }

  async loadPage() {
    // console.log(ROOT_SLUG, "->", this.activatedRoute.snapshot.paramMap.get('slug'));

    try {
      this.pageData = await this.api.pages.get({
        root: ROOT_SLUG,
        slug: this.slug || ''
      });

      this.editYoutubeVideoLinks();
    } catch (ex) {
      console.error(ex);
    }
  }

  editYoutubeVideoLinks() {
    this.pageData?.mediaSection?.items.forEach((item) => {
      if (item.type === 'video') {
        // console.log("YT ID:", getYoutubeID(item.url));
        item.url = `https://www.youtube-nocookie.com/embed/${getYoutubeID(item.url)}`;
      }
    });
  }
}
