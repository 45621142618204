import { Component } from '@angular/core';

@Component({
  selector: 'app-long-arrow-down',
  standalone: true,
  imports: [],
  templateUrl: './long-arrow-down.component.html',
  styleUrl: './long-arrow-down.component.scss'
})
export class LongArrowDownComponent {

}
