import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HeaderComponent } from '../../components/header/header.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: true,
  imports: [RouterOutlet, HeaderComponent],
})
export class MainLayoutComponent {
  appVersion: string = environment.appVersion;


  // @ViewChild('testDiv') private testDiv?: ElementRef<HTMLDivElement>;
  // isTop: boolean = true;
  // private totDeltaY: number = 0;
  // onWheelScroll(event: any) {
  //   if (this.testDiv){
  //     this.totDeltaY += event.deltaY;

  //     const rect = this.testDiv.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isTop = (topShown && bottomShown) || (this.totDeltaY === 0);
  //   }
  //   // console.log("Scroll Event", event, event.deltaY, "->", this.isTop);
  // }
}
