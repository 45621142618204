import { Component, Input } from '@angular/core';
import { ISitePageCard } from '../../models/common';

@Component({
  selector: 'app-prom-card',
  templateUrl: './prom-card.component.html',
  styleUrl: './prom-card.component.scss',
  standalone: true,
  imports: [],
})
export class PromCardComponent {
  @Input() card?: ISitePageCard;
  @Input() size: 'full' | 'half' = 'half';
  @Input() arrowColor: 'blue' | 'green' = 'blue';
}
