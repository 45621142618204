export enum LocaleEnum {
    IT = 'it',
    EN = 'en'
}

export const DEFAULT_LANG = LocaleEnum.IT;


export interface ISitePageLink {
    label: string;
    url?: string;
    route?: string;
}

export interface ISitePageCard {
    title: string;
    body: string;
    link?: ISitePageLink;
    download?: ISitePageLink;
}

export interface ISitePageReference {
    slug: string;
    title: string;
}

export interface IMediaSectionItem {
    type: 'text' | 'video' | 'image' | 'text+image' | 'text+link';

    /** default: "full" */
    size?: 'half' | 'full' | 'third';
    
    title: string;
    body?: string;

    download?: ISitePageLink;

    /** external URL or internal route */
    link?: ISitePageLink;

    /** video or image URL */
    url?: string;
    
    /** cover image for video in URL */
    cover?: string;
}

export interface INewsReference extends ISitePageReference {
    category: string;
    types?: ('dataviz' | 'podcast' | 'report' | 'video')[];

    img?: string;
    date?: Date;
}

export interface IReport {
    title: string,
    body: string,
    img: string,
    route: string,
}

export type ButtonTheme = 'deep-blue' | 'fluo-green';
