@if (items) {
    <div class="routes-to-pages size-{{size}}">
        @for (item of items; track $index) {
            <div id="single-route-card-{{$index}}" class="single-route default-card-border" [ngStyle]="{height: size === 'thirds' ? calculatedHeight : 'auto'}" [routerLink]="['/', baseRoute, item.slug]" data-aos="fade-up">
                <p class="title">{{item.title}}</p>
                <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="">
            </div>
        }
    </div>
}
