/**
  @example ```console.log("YT1", getYoutubeID('https://www.youtube.com/watch?v=8ZLmSNI9G5M&t=1s'));```
  @example ```console.log("YT2", getYoutubeID('https://youtu.be/8ZLmSNI9G5M?si=11z8lX8UjueVj2aB'));```
  @example ```console.log("YT3", getYoutubeID('https://www.youtube.com/watch?v=8ZLmSNI9G5M&t=1s&list=PL1F4Dp9hUwX0p5HsKz2h6ZBzq0VpWVn0N'));```
  @example ```console.log("YT4", getYoutubeID('https://www.youtube.com/embed/8ZLmSNI9G5M?si=11z8lX8UjueVj2aB'));```
  @returns {string | null} The ID of the YouTube video (eg. "8ZLmSNI9G5M"), or the regEx doesn't match
*/
export function getYoutubeID(url?: string): string | null {
  if (!url) { return null; }
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function roundTo(num: number, places: number, type: "floor" | "ceil" | "round" = "round") {
  const factor = 10 ** places;
  switch (type) {
      case "floor":   return Math.floor(num * factor) / factor;
      case "ceil":    return Math.ceil(num * factor) / factor;
      default:        return Math.round(num * factor) / factor;
  }   
}

export function scrollToTop() {
  window.scroll(0, 0);
}
