import { Component, Input } from '@angular/core';
import { INewsReference } from '../../models/common';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { KebabCasePipe } from "../../pipes/kebab-case.pipe";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-section-news-headlines',
  templateUrl: './section-news-headlines.component.html',
  styleUrl: './section-news-headlines.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, KebabCasePipe],
})
export class SectionNewsHeadlinesComponent {
  @Input() news?: INewsReference[] = [];
  @Input() bgColor: string = 'white';
}
