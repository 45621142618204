@if (data?.length) {
    <div class="mobile-header-menu">
        <button mat-icon-button class="menu-button" [matMenuTriggerFor]="firstLevelMenu" aria-label="Menu button with a vertical three dot icon">
            <mat-icon class="menu-icon">more_vert</mat-icon>
        </button>
        
        <mat-menu #firstLevelMenu="matMenu">
            @for (fl of data; track fl.slug) {
                @if (fl.children?.length) {
                    <button mat-menu-item [matMenuTriggerFor]="secondLevelMenu" (mouseenter)="onFirstLevelEnter(fl)">{{ fl.label | uppercase }}</button>
                }
                @else {
                    @if (fl.url) {
                        <a href="{{fl.url}}">
                            <button mat-menu-item>{{ fl.label | uppercase }} ↗</button>
                        </a>
                    }
                    @else if (fl.slug) {
                        <button mat-menu-item [routerLink]="[fl.slug]">{{ fl.label | uppercase }}</button>
                    }
                }
            }
        </mat-menu>

        <mat-menu #secondLevelMenu="matMenu">
            @for (sl of activeFirstLevel?.children; track sl.slug) {
                @if (sl.children?.length) {
                    <button mat-menu-item [matMenuTriggerFor]="thirdLevelMenu" (mouseenter)="onSecondLevelEnter(sl)">{{ sl.label }}</button>
                }
                @else {
                    @if (sl.url) {
                        <a href="{{sl.url}}">
                            <button mat-menu-item>{{ sl.label }} ↗</button>
                        </a>
                    }
                    @else if (sl.slug) {
                        <button mat-menu-item [routerLink]="[firstLevelSlug, sl.slug]">{{ sl.label }}</button>
                    }
                }
            }
        </mat-menu>

        <mat-menu #thirdLevelMenu="matMenu">
            @for (item of activeSecondLevel?.children; track item.slug) {
                @if (item.url) {
                    <a href="{{item.url}}">
                        <button mat-menu-item>{{ item.label }} ↗</button>
                    </a>
                }
                @else if (item.slug) {
                    <button mat-menu-item [routerLink]="[firstLevelSlug, item.slug]">{{ item.label }}</button>
                }
            }
        </mat-menu>
    </div>
}
