import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

import { RestClientService } from '../../services/rest-client.service';

import { IHeaderRow } from '../../models/header';
import { AmplifyAuthService } from '../../services/amplify-auth.service';
import { ArDialogAuthComponent } from '../ar-dialog-auth/ar-dialog-auth.component';
import { DialogComponent } from '../dialog/dialog.component';
import { HeaderDataService } from '../../services/header-data.service';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { MobileHeaderMenuComponent } from "../mobile-header-menu/mobile-header-menu.component";
import { PageRoots } from '../../models/pages';
import { scrollToTop } from '../../services/utilities.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, MatDialogModule, MatTooltipModule, MatMenuModule, PromAnimatedButtonComponent, MobileHeaderMenuComponent, TranslateModule],
})
export class HeaderComponent implements OnInit, OnDestroy {

  expertisePageRoot: PageRoots = 'expertise';

  @Input() isTop: boolean = true;
  
  isLoggedAR : boolean = false;
  showLogout : boolean = false;

  /* private poolStatusSubscription?: Subscription | null = null;*/
  COOKIE_TOKEN_AR : string = 'prom-ar-token'; 

  _headerData?: IHeaderRow[];
  get headerData(): IHeaderRow[] | undefined {
    return this._headerData /* ?? this.activatedRoute.snapshot.data['headerData'] as IHeaderRow[] | undefined */;
  }

  activeFirstLevel?: string;

  routerEventsSubscription?: Subscription;

  constructor(
    private readonly amplifyService: AmplifyAuthService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
    private readonly headerDataService: HeaderDataService,
    private readonly restClient: RestClientService,
  ) { }

  ngOnInit(): void {
    this.loadHeaderData();

    this.checkPoolStatus();

    this.routerEventsSubscription = this.router.events.subscribe((event: any) => {     
      if(event){
        this.checkPoolStatus();
      }
    });
  }

  ngOnDestroy(): void {
    this.checkPoolStatus();
    this.routerEventsSubscription?.unsubscribe();
  }

  async loadHeaderData() {
    if (!this.headerData) {
      this._headerData = await this.headerDataService.getHeaderData();
    }
  }


  get currentFirstLevel(): string {
    if (this.activeFirstLevel) {
      return '';
    }
    // console.log(window.location.href.replace(window.location.origin, '').split('/')[1]);
    return window.location.href.replace(window.location.origin, '').split('/')[2];
  }

  get secondLevel(): IHeaderRow[] {
    return this.headerData?.find((x: any) => x.slug === this.activeFirstLevel)?.children ?? [];
  }

  get showSecondLevel() {
    return !!this.secondLevel;
  }

  isActiveBarMovingRight: boolean = false;
  isActiveBarMovingLeft: boolean = false;
  previousActiveFirstLevel?: string;
  onMouseOverFirstLevel(code: string) {
    // console.log("enter", code);
    this.previousActiveFirstLevel = this.activeFirstLevel + "";
    const isMovingToOtherFirstLevel: boolean = this.previousActiveFirstLevel !== code;
    this.activeFirstLevel = code;

    if (isMovingToOtherFirstLevel && this.headerData) {
      const oldIndex = this.headerData.findIndex((x: any) => x.slug === this.previousActiveFirstLevel) ?? -1;
      const newIndex = this.headerData.findIndex((x: any) => x.slug === code) ?? -1;
      this.isActiveBarMovingRight = oldIndex !== -1 && newIndex !== -1 && oldIndex < newIndex;
      this.isActiveBarMovingLeft =  oldIndex !== -1 && newIndex !== -1 &&oldIndex > newIndex;

      setTimeout(() => {
        this.isActiveBarMovingLeft = false;
        this.isActiveBarMovingRight = false;
      }, 190);

      setTimeout(() => {
        this.previousActiveFirstLevel = undefined;
      }, 400);
    }
  }

  onMouseExitFirstLevel() {
    this.activeFirstLevel = undefined;
  }

  changeLanguage(newLocale: string) {
    this.translate.use(newLocale ?? (this.translate.currentLang === 'en' ? 'it' : 'en'));
    // this.translate.reloadLang(this.translate.currentLang);
    this.restClient.setupLocalization(newLocale);
    this.reloadCurrentRoute(newLocale);
  }

  reloadCurrentRoute(newLocale: string) {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.startsWith("/it/") ? currentUrl.replace("/it/", `/${newLocale}/`) : currentUrl.replace("/en/", `/${newLocale}/`);

    this.router.navigateByUrl(currentUrl);
  }

  clickOnThirdLevel() {
    this.activeFirstLevel = undefined;
    scrollToTop();
  }


  /** RESERVED AREA */

  private async checkPoolStatus() {
    this.isLoggedAR = await this.amplifyService.isLoggedAR(this.COOKIE_TOKEN_AR);
    this.showLogout = this.isLoggedAR && window.location.href.includes('reserved-area');    
  }

  async login() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px',
      id: 'modal-ar-login'
    });
    dialogRef.afterClosed().subscribe( async () => {
      this.checkPoolStatus();
    })
  }

  async logout() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant('AR.LOGOUT_CONFIRM'), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        const r = await this.amplifyService.logout('ar');
        this.amplifyService.removeTokenAR(this.COOKIE_TOKEN_AR);
        this.router.navigate(['/']);
        this.checkPoolStatus();    
      }
      else{
        dialogRef.close();
      }
    });
  }  

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', 'reserved-area']);
    else window.location.reload();
  }
}
