<div class="prom-animated-button theme--{{theme}} size-{{size}} arrow-{{arrow}}">
    <div class="button">
        <span class="label">
            <ng-content></ng-content>
        </span>
        @switch (arrow) {
            @case ('down') {
                <span class="arrow-bg"><img src="/assets/icons/down_arrow.svg"/></span>
            }
            @case ('right') {
                @if (theme == 'deep-blue') {
                    <span class="arrow-bg"><img src="/assets/icons/right_arrow__white.svg"/></span>
                }
                @else {
                    <span class="arrow-bg"><img src="/assets/icons/right_arrow__blue.svg"/></span>
                }
            }
            @case ('top-right') {
                @if (theme == 'deep-blue') {
                    <span class="arrow-bg"><img src="/assets/icons/right_arrow__white.svg"/></span>
                }
                @else {
                    <span class="arrow-bg"><img src="/assets/icons/right_arrow__blue.svg"/></span>
                }
            }
            @default { }
        }
    </div>
</div>
